export const config = {
    RPC_URL: 'https://rpc-testnet.cats.ac',
    REST_URL: 'https://api-testnet.cats.ac',
    EXPLORER_URL: 'https://explorer-testnet.catscoin.ac',
    STAKING_URL: 'https://testnet.cats.ac/stake',
    NETWORK_NAME: 'Cats Testnet',
    NETWORK_TYPE: 'testnet',
    CHAIN_ID: 'cats-testnet-1',
    CHAIN_NAME: 'Cats testnet',
    COIN_DENOM: 'CATS',
    COIN_MINIMAL_DENOM: 'ucats',
    COIN_DECIMALS: 6,
    PREFIX: 'cats',
    COIN_TYPE: 118,
    COINGECKO_ID: '-',
    DEFAULT_GAS: 200000,
    GAS_PRICE_STEP_LOW: 0.0025,
    GAS_PRICE_STEP_AVERAGE: 0.025,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go'],
};